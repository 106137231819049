import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { AverageScore } from 'lib/ekomi/types';
import StarRating from './StarRating';

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StarHeading = styled(Typography)(({ theme }) => ({
  margin: 0,
  fontSize: '13px',
  [theme.breakpoints.down('md')]: {
    lineHeight: '1em',
    fontSize: '10px',
  },
}));

const StarText = styled(Typography)(({ theme }) => ({
  fontSize: '7px',
  marginBottom: 0,
  [theme.breakpoints.down('md')]: {
    lineHeight: '1em',
  },
}));

const EkomiWrapper = styled('div')`
  margin-right: 10px;
`;

const ThumpsImage = styled('img')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '40px',
    height: '40px',
  },
}));

export default function Ekomi({
  rounded_average,
  number_of_ratings,
  seal,
}: AverageScore) {
  const roundedAverage = parseFloat(
    parseFloat(rounded_average).toFixed(1),
  ).toLocaleString('de-DE');

  return (
    <Wrapper>
      <EkomiWrapper>
        <ThumpsImage
          src={`/assets/certificates/ekomi/${seal}.webp`}
          width="60"
          height="60"
          alt={`Ekomi ${seal} Seal`}
          role="img"
        />
      </EkomiWrapper>

      <div>
        <StarHeading data-testid="heading">
          smava.de - {roundedAverage}/5
        </StarHeading>
        <StarRating stars={parseFloat(rounded_average)} />
        <StarText data-testid="text">
          aus {number_of_ratings} Bewertungen
        </StarText>
      </div>
    </Wrapper>
  );
}
